import { ref, onBeforeMount } from '@vue/composition-api'
import api from '@/app/certificado-digital/shared/services/api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import EventBus from '@app/shared/mixins/event-bus/eventBus'

export default function useCertificadoDigital(modoEdicao) {
  const toast = useToast()
  const form = ref(null)
  const { router } = useRouter()

  onBeforeMount(() => {
    form.value = {
      id: 0,
      arquivo: {},
      senha: '',
      porProcuracao: false,
      cadastrarEmpresas: false,
      habilitarCampos: false,
      cadastroFederal: '',
      razaoSocial: '',
      cadastroFederalTipo: 'cnpj',
      empresasVinculadas: {
        vinculadas: [],
        naoVinculadas: [],
        totalVinculadas: 0,
        totalNaoVinculadas: 0,
      },
    }
  })

  const carregar = id => {
    api.getCertificado(id)
      .then(payload => {
        form.value = payload.data
        form.value.arquivo = {
          fileName: payload.data.nomeArquivo,
        }
      })
      .catch(err => {
        throw err
      })
      .finally(() => {
        EventBus.$emit('finalizaLoading')
      })
  }

  const carregarEmpresasParaVinculo = () => {
    api.getEmpresasParaVinculo()
      .then(payload => {
        form.value.empresasVinculadas.naoVinculadas = payload.data
        form.value.empresasVinculadas.totalNaoVinculadas = payload.data.length
        form.value.empresasVinculadas.totalVinculadas = 0
      })
      .catch(err => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Não foi possível carregar as empresas para vinculo.',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
        throw err
      })
      .finally(() => {
        EventBus.$emit('finalizaLoading')
      })
  }

  const salvarOuAlterar = () => {
    if (modoEdicao && form.value.id > 0) {
      return new Promise((resolve, reject) => {
        EventBus.$emit('inicioLoading')
        api.alterarCertificado(form.value.id, form.value)
          .then(() => {
            resolve('Certificado digital atualizado com sucesso')
            router.go(-1)
          })
          .catch(err => {
            // eslint-disable-next-line no-use-before-define
            tratarErro(err, reject)
          })
          .finally(() => {
            EventBus.$emit('finalizaLoading')
          })
      })
    }
    form.value.cadastroPorCertificado = 'none'
    return new Promise((resolve, reject) => {
      EventBus.$emit('inicioLoading')
      api.salvarCertificado(form.value)
        .then(() => {
          resolve('Certificado digital cadastrado com sucesso')
          router.go(-1)
        })
        .catch(err => {
          // eslint-disable-next-line no-use-before-define
          tratarErro(err, reject)
        })
        .finally(() => {
          EventBus.$emit('finalizaLoading')
        })
    })
  }

  function tratarErro(err, reject) {
    if (err.response.data.message === 'Erro ao obter razão social ou cadastro do certificado digital') {
      form.value.habilitarCampos = true
    }
    if (!err.response || (err.response && err.response.status !== 500)) {
      reject(err)
    } else {
      Vue.prototype.$toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.message === 'A senha de rede especificada não está correta.' ? 'Senha Inválida!' : err.response.data.message,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    }
  }

  const salvar = () => {
    form.value.status = 'none'
    return salvarOuAlterar()
  }

  const updateResponsavelLegal = (empresaId, responsavelLegal) => {
    api.alterarResponsavelLegal(empresaId, responsavelLegal)
  }

  const cancelar = () => {
    router.go(-1)
  }

  return {
    salvar,
    carregar,
    cancelar,
    form,
    carregarEmpresasParaVinculo,
    updateResponsavelLegal,
  }
}
