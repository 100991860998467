<template>
  <cadastro-base
    :salvar="salvar"
    :cancelar="cancelar"
    :is-loading="isLoading"
  >
    <template slot="conteudo">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Certificado Digital A1"
            label-for="certificadoDigitalInput"
          >
            <!-- <validation-provider
              #default="{ errors }"
              :rules="{required: true}"
              name="Certificado Digital"
            > -->
            <botao-upload
              id="btnUpload"
              :ref="`uploadEmpresas`"
              v-model="form.arquivo"
              :extensions="'.pfx'"
              :upload-auto="false"
              :options="uploadOpt"
              @removed="removerArquivo()"
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Senha"
            label-for="SenhaInput"
          >
            <senha-input
              id="id"
              v-model="form.senha"
              :mostrar-senha="true"
              :validacao-direita="true"
              auto-complete-senha="new-password"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
        >
          <b-form-checkbox
            v-model="form.porProcuracao"
            value="true"
            unchecked-value="false"
          >
            Por Procuração
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row
        v-if="form.habilitarCampos"
        style="margin-top: 1rem;"
      >
        <b-col cols="1">
          <b-form-group
            label-for="tipoInscricaoInput"
            label="Tipo inscrição"
          >
            <b-select
              id="tipoInscricaoInput"
              ref="tipoInscricao"
              v-model="form.cadastroFederalTipo"
              :options="[{text:'CNPJ', value:'cnpj'},{value:'cpf',text:'CPF'}]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <cadastro-federal-input
              ref="cadastroFederal"
              v-model="form.cadastroFederal"
              class="mb-50"
              :is-to-buscar-dados="false"
              :is-cnpj="form.cadastroFederalTipo === 'cnpj'"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="form.cadastroFederalTipo !== 'cnpj'"
          cols="3"
        >
          <b-form-group
            label="Razão Social"
            label-for="razaoSocialInput"
          >
            <b-input
              id="razaoSocialInput"
              v-model="form.razaoSocial"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <vinculo-empresas
            v-model="form.empresasVinculadas"
            :cadastro-usuario="false"
            :visualizacao-opcoes="visualizacao"
            :filtros-vinculadas="filtrosVinculadas"
            :filtros-nao-vinculadas="filtrosNaoVinculadas"
            :filtros-rapidos="filtrosRapidos"
            :tem-filtro-rapido="true"
            :tem-busca="true"
            @eventClickCustom="clickCustomEmpresas($event)"
          />
        </b-col>
      </b-row>
    </template>
  </cadastro-base>
</template>
<style lang="scss">
.swal-m {
  width: 35%;
}</style>
<script>

import api from '@/app/certificado-digital/shared/services/api'
import {
  defineComponent, ref,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import { required } from '@validations'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import { ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import useCertificadoDigital from './useCertificadoDigital'

export default defineComponent({
  name: 'CertificadoDigital',
  components: {
    ValidationProvider,
    CadastroBase: () => import('@pilar/components/cadastro-base/CadastroBase.vue'),
    SenhaInput: () => import('@/app/shared/components/senha-input/SenhaInput.vue'),
    BotaoUpload: () => import('@pilar/components/botao-upload/BotaoUpload.vue'),
    VinculoEmpresas: () => import('@pilar/components/vinculo-empresas/VinculoEmpresas.vue'),
    CadastroFederalInput: () => import('@pilar/components/cadastro-federal-input/CadastroFederalInput.vue'),
  },
  data() {
    return {
      required,
      visualizacao: {
        colunasAdicionais: [{ label: 'Fim da vigência', field: 'fimVigencia', tdClass: 'col-tamanho-data-2' },
          {
            label: '', field: 'responsavelLegal', icon: 'fa-solid fa-user-tie', colorIcon: this.corIcone, eventClickCustom: true, ocultarNaoVinculados: true,
          }],
        placeholderBusca: 'Buscar por palavra chave e CNPJ/CPF',
        evento(id, filtro) {
          return api.getEmpresasPorCertificado(id, filtro)
        },
      },
      filtrosVinculadas: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'FimVigencia'], valor: null }],
      filtrosNaoVinculadas: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'FimVigencia'], valor: null }],
      filtrosRapidos: [
        {
          id: 'ComCertificado',
          colunas: ['ComCertificado'],
          label: 'Com certificado',
          valor: this.$route.params.id,
        },
        {
          id: 'SemCertificado',
          colunas: ['SemCertificado'],
          label: 'Sem certificado / procuração',
          valor: this.$route.params.id,
        },
      ],
      isLoading: true,
    }
  },
  setup() {
    const { route } = useRouter()
    const modoEdicao = route.value.path.indexOf('editar') > 0
    const {
      salvar,
      carregar,
      cancelar,
      form,
      carregarEmpresasParaVinculo,
      updateResponsavelLegal,
    } = useCertificadoDigital(modoEdicao)

    if (modoEdicao) {
      carregar(route.value.params.id, route.value.params.filtro)
    } else {
      carregarEmpresasParaVinculo()
    }

    const removerArquivo = () => {
      form.value.arquivo = {
        url: null,
        fileName: null,
      }
      form.value.porProcuracao = !form.value.porProcuracao
      form.value.porProcuracao = !form.value.porProcuracao
    }

    const corIcone = payload => {
      if (payload) {
        return 'text-success'
      }
      return 'text-muted'
    }

    const clickCustomEmpresas = params => {
      if (params.column.field === 'responsavelLegal') {
        const temp = form.value.empresasVinculadas.vinculadas.find(find => find.id === params.row.id)
        temp.responsavelLegal = !params.row.responsavelLegal
        updateResponsavelLegal(params.row.id, !params.row.responsavelLegal)
      }
    }

    const uploadOpt = ref(null)

    return {
      salvar,
      carregar,
      cancelar,
      form,
      removerArquivo,
      uploadOpt,
      corIcone,
      clickCustomEmpresas,
    }
  },
  created() {
    EventBus.$on('finalizaLoading', () => this.atualizaLoading(false))
    EventBus.$on('inicioLoading', () => this.atualizaLoading(true))
  },
  methods: {
    atualizaLoading(status) {
      this.isLoading = status
    },
  },
})
</script>
